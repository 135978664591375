import loadable from '@loadable/component';

export default {
  PdpVolumePrices: loadable(() => import(/*webpackChunkName: "pdp"*/ './volumePrices')),
  PdpSpecifications: loadable(() => import(/*webpackChunkName:"pdp"*/'./specifications')),
  PdpAttachments: loadable(() => import(/*webpackChunkName:"pdp"*/ './attachments')),
  PdpAddToWishlist: loadable(() => import(/*webpackChunkName:"pdp"*/'./addToWishlist')),
  PdpQuantityBox: loadable(() => import(/*webpackChunkName:"pdp"*/'./quantityBox')),
  PdpAddToCart: loadable(() => import(/*webpackChunkName:"pdp"*/'./addToCart')),
  PdpBomComponents: loadable(() => import(/*webpackChunkName:"pdp"*/'./bomComponents')),
  PdpMediaGallery: loadable(() => import(/*webpackChunkName:"pdp"*/'./mediaGallery')),
  PdpRelatedProducts: loadable(() => import(/*webpackChunkName:"pdp"*/'./relatedProducts')),
  PdpDiscounts: loadable(() => import(/*webpackChunkName:"pdp"*/'./discounts')),
  PdpStockIndicator: loadable(() => import(/*webpackChunkName:"pdp"*/'./stockIndicator')),
  PdpPrice: loadable(() => import(/*webpackChunkName:"pdp"*/'./price')),
  PdpReviews: loadable(() => import(/*webpackChunkName:"pdp"*/'./reviews')),
  PdpReviewsSummary: loadable(() => import(/*webpackChunkName:"pdp"*/'./reviewsSummary')),
  PdpDescription: loadable(() => import(/*webpackChunkName:"pdp"*/'./description')),
  PdpShortDescription: loadable(() => import(/*webpackChunkName:"pdp"*/'./shortDescription')),
  PdpUomSelector: loadable(() => import(/*webpackChunkName:"pdp"*/'./uomSelector')),
  PdpVariantDropdowns: loadable(() => import(/*webpackChunkName:"pdp"*/'./variantDropdowns')),
  PdpSalesAgreementSelector: loadable(() => import(/*webpackChunkName:"pdp"*/'./agreementSelector')),
  PdpNonOrderableMessage: loadable(() => import(/*webpackChunkName:"pdp"*/'./nonOrderableMessage')),
  PdpItemNo: loadable(() => import(/*webpackChunkName:"pdp"*/'./itemNo')),
  PdpUomAndTaxIndicator: loadable(() => import(/*webpackChunkName:"pdp"*/'./uomAndTaxIndicator')),
  PdpCompareSelector: loadable(() => import(/*webpackChunkName:"pdp"*/'./compareSelector')),
  PdpRetailOffers: loadable(() => import(/*webpackChunkName:"pdp"*/'./retailOffers')),
  PdpPriceInfoExtraFields: loadable(() => import(/*webpackChunkName:"pdp"*/'./priceInfoExtraFields')),
  PdpProductGroupLink: loadable(() => import(/*webpackChunkName:"pdp"*/'./productGroupLink')),
  PdpBomComponentWithAddtoCart: loadable(() => import(/*webpackChunkName:"pdp"*/'./bomComponentWithAddToCart')) // Ticket 173762: [Saris] 3.2 Bill of Materials – Link to PDP per BOM component with add to cart button and the quantity boxes
};
